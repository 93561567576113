<template>
  <div class="">
      <component :is="layout">
          <table border="1" class="table text-white text-center w-100">
              <thead>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Subject</th>
                  <th>Message</th>
              </thead>
              <tbody v-if="messages">
                  <tr v-for="message in messages.data" :key="message.id">
                      <td>{{message.name}}</td>
                      <td>{{message.email}}</td>
                      <td>{{message.subject}}</td>
                      <td>{{message.message}}</td>
                  </tr>
              </tbody>
          </table>
          
      </component>
  </div>
</template>

<script>
import axiosConfig from '../../axiosConfig';
import axiosObject from '../../axiosHandler';
export default {
    components:{

    },
    computed: {
    layout() {
      return this.$route.meta.layout;
    },

    },
    data(){
        return{
         messages:null
        }
    },
    methods:{
        getMessages(page =1)
    {
      axiosObject.get('/api/admin/messages?page='+page,axiosConfig).then(function(response){
          this.messages = response.data.messages
      }.bind(this))
    }
    },
  created()
  {
      this.getMessages()
  }
}
</script>

<style>

</style>
